import React, { useState, useEffect } from "react";
import MaintenanceService from "../services/maintenance.service";
import EventBus from "../common/EventBus";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import PaginatedTable from "../common/PaginatedTable";

const MaintenanceSummary = () => {
  const [data, setData] = useState([]);
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const summaryResponse = await MaintenanceService.getSummaryJson();
        setData(summaryResponse.data);
        setLoading(false);
      } catch (error) {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        setLoading(false);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    };

    const fetchActions = async () => {
      try {
        const actionsResponse = await MaintenanceService.getActionsJson();
        setActions(actionsResponse.data);
      } catch (error) {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    };

    fetchSummary();
    fetchActions();
  }, []);

  const calculateInventoryCounts = () => {
    const inventoryCounts = {};
    actions.forEach((action) => {
      action.inventories.forEach((inv) => {
        const key = `${inv.label}`;
        inventoryCounts[key] = (inventoryCounts[key] || 0) + 1;
      });
    });
    return inventoryCounts;
  };

  const renderPieChartWithTable = (
    pieData,
    tableData,
    pieTitle,
    pieHeaders,
    tableHeaders,
    displayedHeaders,
    fileName
  ) => (
    <Box display="flex" flexDirection="row" mb={3} alignItems="flex-start">
      <Box flex={1} p={1}>
        <Typography variant="h6" gutterBottom>
          {pieTitle}
        </Typography>
        <PieChart width={400} height={400}>
          <Pie
            data={pieData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={150}
            fill="#8884d8"
            label
          >
            {pieData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={index % 2 === 0 ? "#0088FE" : "#00C49F"}
              />
            ))}
          </Pie>
          <Tooltip
            content={({ payload }) => {
              if (payload && payload.length) {
                const {
                  name,
                  value,
                  payload: { additionalInfo },
                } = payload[0];
                return (
                  <div
                    style={{
                      padding: "10px",
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                    }}
                  >
                    <strong>{name}</strong>
                    <br />
                    Value: {value}
                    {additionalInfo && (
                      <div>Additional Info: {additionalInfo}</div>
                    )}
                  </div>
                );
              }
              return null;
            }}
          />
        </PieChart>
      </Box>
      <Box flex={1} p={1}>
        <Typography variant="h6" gutterBottom>
          Table Data for {pieTitle}
        </Typography>
        <PaginatedTable
          data={tableData}
          headers={tableHeaders}
          displayedHeaders={displayedHeaders}
          itemsPerPage={itemsPerPage}
          fileName={fileName}
        />
      </Box>
    </Box>
  );

  const pieChartData = actions.map((action) => ({
    name: action.number,
    value: action.inventories.length,
    additionalInfo: action.inventories.map((inv) => inv.label).join(", "),
  }));

  const inventoryCounts = calculateInventoryCounts();
  const inventoryPieChartData = Object.keys(inventoryCounts).map((key) => ({
    name: key,
    value: inventoryCounts[key],
  }));

  const pieHeaders = [
    { label: "Bike Number", key: "name" },
    { label: "Inventories", key: "additionalInfo" },
  ];

  const inventoryPieHeaders = [
    { label: "Inventory Name", key: "name" },
    { label: "Count", key: "value" },
  ];

  const tableHeaders = [
    "Bike Number",
    "Last Maintenance Date",
    "Subscription Start Date",
    "Duration Before Maintenance",
    "Bike Life",
    "Inventories",
    "Action",
    "Actions Done",
  ];

  const tableData = data.map((item) => ({
    "Bike Number": item.bikeNumber,
    "Last Maintenance Date": item.maintenanceStartDate,
    "Subscription Start Date":
      item.subscriptionStartDate === "N/A"
        ? item.subscriptionStartDate
        : new Date(item.subscriptionStartDate).toLocaleDateString(),
    "Duration Before Maintenance": item.durationBeforeMaintenance,
    "Bike Life": `${item.bikeLife} ${item.bikeLifeStatus ? "🟢" : "🔴"}`,
    Inventories: item.inventories
      .map((inv) => `${inv.number} - ${inv.label} (${inv.type})`)
      .join("; "),
    Action: item.action,
    "Actions Done": Array.isArray(item.actionsDone)
      ? item.actionsDone
          .map(
            (action) =>
              `${action.category} - ${action.subCategory} (${action.action})`
          )
          .join("; ")
      : "N/A",
  }));

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Bike Maintenance Summary
      </Typography>
      <Box mb={2}>
        <PaginatedTable
          data={tableData}
          headers={tableHeaders}
          itemsPerPage={itemsPerPage}
          fileName="MaintenanceData.csv"
        />
      </Box>
      {renderPieChartWithTable(
        pieChartData,
        pieChartData.map((dataItem) => ({
          "Bike Number": dataItem.name,
          Count: dataItem.value,
          Inventories: dataItem.additionalInfo,
        })),
        "Total Inventories by Bike",
        pieHeaders,
        ["Bike Number", "Inventories"],
        ["Bike Number", "Inventories"],
        "PieChartData.csv"
      )}
      {renderPieChartWithTable(
        inventoryPieChartData,
        inventoryPieChartData,
        "Total Inventory Usage",
        inventoryPieHeaders,
        ["name", "value"],
        ["Inventory Name", "Count"],
        "InventoryPieChartData.csv"
      )}
    </Box>
  );
};

export default MaintenanceSummary;

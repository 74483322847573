import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
} from "@material-ui/core";
import { CSVLink } from "react-csv";
import Pagination from "@material-ui/lab/Pagination";

const PaginatedTable = ({
  data,
  headers,
  itemsPerPage,
  fileName,
  displayedHeaders,
}) => {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  if (!displayedHeaders) {
    displayedHeaders = headers;
  }
  // Handle search input changes
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter data based on search query
  const filteredData = data.filter((row) => {
    return headers.some((header) =>
      (row[header] || "")
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  });

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  if (!displayedHeaders) {
    displayedHeaders = headers;
  }

  return (
    <Paper>
      <div style={{ padding: "10px" }}>
        <label className="filterLabelInput" htmlFor="searchQuery">
          Search
        </label>

        <input
          className="form-control filterInput"
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {displayedHeaders.map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row, index) => (
              <TableRow key={index}>
                {headers.map((header, idx) => (
                  <TableCell key={idx}>{row[header]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <CSVLink
          data={filteredData}
          headers={displayedHeaders.map((header, index) => ({
            label: header,
            key: headers[index],
          }))}
          filename={fileName}
        >
          <Button variant="contained" color="primary">
            Export
          </Button>
        </CSVLink>
        <Pagination
          count={Math.ceil(filteredData.length / itemsPerPage)}
          page={page}
          onChange={(event, value) => setPage(value)}
        />
      </div>
    </Paper>
  );
};

export default PaginatedTable;
